<template>
  <div>
    <v-navigation-drawer width="400" v-model="localDrawer" right temporary app>
      <v-list-item :class="localAula.color">
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">{{
            localAula.tipo_de_aula
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Data</v-list-item-title>
          <p class="font-weight-light text-justify">
            {{ localAula.data_formatada }}
          </p>
          <v-list-item-title class="mt-4"
            >Horário:
            {{
              localAula.gestao.relacoesDiasHorarios.find(
                (relacao) => relacao.horario.id === aula.horario_id
              ).horario.descricao
            }}</v-list-item-title
          >
          <v-list-item-title class="mt-4">Conteúdo</v-list-item-title>
          <p class="font-weight-light text-justify">
            {{ localAula.conteudo }}
          </p>
          <v-divider></v-divider>
          <v-list-item-title class="mt-4">Metodología</v-list-item-title>
          <p class="font-weight-light text-justify">
            {{ localAula.metodologia }}
          </p>
          <v-divider></v-divider>
          <v-list-item-title class="mt-4">Situação</v-list-item-title>
          <v-list-item-title>
            <v-chip label :color="localAula.color">
              {{ localAula.descricaoSituacao }}
            </v-chip>
          </v-list-item-title>
          <v-list-item-title class="mt-4">Motivo</v-list-item-title>
          <p class="font-weight-light">
            {{ localAula.motivo ? localAula.motivo : "Sem motivo." }}
          </p>
        </v-list-item-content>
      </v-list-item>
    </v-navigation-drawer>
    <e-modal-duplicar-aula
      :submittingForm="submittingForm"
      :dialog="dialog.duplicar"
      :gestaoDeAulas="localAula"
      :form="{ ...localAula }"
      @dialogChange="dialog.duplicar = $event"
    >
    </e-modal-duplicar-aula>
    <e-modal-exportar-aula
      :submittingForm="submittingForm"
      :dialog="dialog.exportar"
      :form="{ ...localAula }"
      @dialogChange="dialog.exportar = $event"
    >
    </e-modal-exportar-aula>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    aula: {
      type: Object,
      required: true,
    },
  },
  watch: {
    value() {
      this.localDrawer = this.value;
    },
    localDrawer() {
      this.$emit("input", this.localDrawer);
    },
  },
  computed: {
    localAula() {
      return this.aula;
    },
  },
  data() {
    return {
      localDrawer: this.value,
      dialog: {
        duplicar: false,
        exportar: false,
      },
      submittingForm: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
