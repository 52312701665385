<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'gestoesDeAulas' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Vínculo
        </h1>
      </v-col>
    </v-row>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col class="pt-0 pb-0" cols="12">
            <ValidationProvider name="Professor" rules="required" v-slot="{ errors }">
              <e-label>Professor</e-label>
              <e-autocomplete
                :items="professoresList"
                :return-object="false"
                :item-value="(value) => value.id"
                :item-text="(val) => `${val.cpf} - ${val.nome}`"
                :error-messages="errors"
                :loading="carregandoProfessores"
                v-model="form.instrutor_id"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row
          class="animate__animated animate__zoomIn"
          style="animation-duration: 0.5s"
          v-for="relacao of relacoes"
          :key="relacoes.indexOf(relacao)"
        >
          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Turma" rules="required" v-slot="{ errors }">
              <e-label>Turma</e-label>
              <e-autocomplete
                :items="turmasList"
                :return-object="false"
                :item-value="(value) => value.id"
                :item-text="(val) => (val ? `${val.descricao} - (${val.turno.descricao})` : '')"
                :error-messages="relacao.errors ? relacao.errors : errors"
                :loading="carregandoTurmas"
                v-model="relacao.turma_id"
                :disabled="editing"
                @change="getHorariosDaTurma(relacao.turma_id)"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>

          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Disciplina" rules="required" v-slot="{ errors }">
              <e-label>Componentes Curriculares</e-label>
              <e-autocomplete
                :items="disciplinasList"
                :return-object="false"
                :item-value="(value) => value.id"
                :item-text="(val) => val.descricao"
                :error-messages="relacao.errors ? relacao.errors : errors"
                :loading="carregandoDisciplinas"
                v-model="relacao.disciplina_id"
                :disabled="editing"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>

          <v-col class="pt-0 pb-0" cols="4">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <ValidationProvider name="Carga horária" rules="required" v-slot="{ errors }">
                  <e-label>CH SEMANAL</e-label>
                  <v-text-field
                    :error-messages="errors"
                    v-bind="attrs"
                    v-on="on"
                    v-model="relacao.carga_horaria"
                    label="20"
                    dense
                    solo
                  />
                </ValidationProvider>
              </template>
              <span>Carga horária semanal do professor</span>
            </v-tooltip>
          </v-col>

          <v-col class="pt-0 pb-0" cols="4">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <ValidationProvider name="Carga anual" rules="required" v-slot="{ errors }">
                  <e-label>CH ANUAL</e-label>
                  <v-text-field
                    :error-messages="errors"
                    v-bind="attrs"
                    v-on="on"
                    v-model="relacao.ch_anual_disciplina"
                    label="200"
                    dense
                    solo
                  />
                </ValidationProvider>
              </template>
              <span>Carga horária anual da disciplina</span>
            </v-tooltip>
          </v-col>

          <v-col v-if="!editing" cols="5">
            <v-card v-if="relacoesDiasHorarios.length > 0">
              <v-card-title>
                <e-label>Relação de Dias e Horários</e-label>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="7" class="text-left">
                    <span class="text-uppercase">Horário</span>
                  </v-col>
                  <v-col cols="" class="text-left">
                    <span class="text-uppercase">Dia</span>
                  </v-col>
                </v-row>
                <v-list>
                  <v-list-item v-for="(item, i) in relacao.horarios" :key="i">
                    <v-row>
                      <v-col cols="7" class="text-left">
                        {{ item.horario.descricao }}
                      </v-col>
                      <v-col cols="5" class="text-left">
                        {{ item.dia.descricao }}
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
            <v-card v-else>
              <v-card-title>
                <e-label>Relação de Dias e Horários</e-label>
              </v-card-title>
              <v-card-text>
                <div>
                  <p class="text-center">Não há horários selecionados</p>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col v-if="editing" cols="3">
            <v-card>
              <v-card-title>
                <e-label>Relação de Dias e Horários</e-label>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="7" class="text-left">
                    <span class="text-uppercase">Horário</span>
                  </v-col>
                  <v-col cols="" class="text-left">
                    <span class="text-uppercase">Dia</span>
                  </v-col>
                </v-row>
                <v-list>
                  <v-list-item v-for="(item, i) in relacoesDiasHorariosEdit" :key="i">
                    <v-row>
                      <v-col cols="7" class="text-left">
                        {{ item.horario.descricao }}
                      </v-col>
                      <v-col cols="5" class="text-left">
                        {{ item.dia.descricao }}
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>

          <template>
            <v-btn color="green white--text" class="mt-7" @click="alterRelacaoIdDialog(relacao)">
              {{ editing ? "Editar dias e horários" : "Adicionar dias e horários" }}
            </v-btn>
          </template>

          <v-col class="pt-0 pb-0" cols="1">
            <v-btn
              v-if="relacoes.length > 1"
              class="mt-7"
              color="error"
              @click="() => (relacoes = relacoes.filter((relacaoObj) => relacaoObj !== relacao))"
              :disabled="submittingForm"
              ><v-icon small>fa-trash</v-icon></v-btn
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="d-flex justify-space-between">
            <div class="d-flex">
              <v-btn
                color="primary"
                :disabled="submittingForm"
                :loading="submittingForm"
                type="submit"
                >Enviar Dados</v-btn
              >
              <v-btn
                class="ml-3"
                v-if="!editing"
                color="secondary"
                @click="adicionarRelacao"
                :disabled="submittingForm"
              >
                Adicionar
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-dialog transition="dialog-top-transition" v-model="dialogVisible" max-width="800">
          <template>
            <v-card>
              <v-toolbar color="primary" dark
                >Vinculo de dias e horários
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogVisible = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-row class="mt-4">
                  <v-col class="pt-0 pb-0" cols="4">
                    <e-label>Dias da semana</e-label>
                    <v-select
                      v-model="dia_da_semana"
                      :items="diasDaSemana"
                      :return-object="false"
                      :item-value="(value) => value"
                      :item-text="(value) => value.descricao"
                      dense
                      solo
                      label="selecione"
                    ></v-select>
                  </v-col>

                  <v-col class="pt-0 pb-0" cols="4">
                    <e-label>Horarios</e-label>
                    <v-select
                      v-model="horario_selecionado_id"
                      :items="horariosList"
                      :return-object="false"
                      :item-value="(value) => value"
                      :item-text="(value) => value.descricao"
                      dense
                      solo
                      label="selecione"
                    ></v-select>
                  </v-col>

                  <v-col class="pt-7 pb-0" cols="4">
                    <v-btn
                      :disabled="!dia_da_semana || !horario_selecionado_id"
                      color="green"
                      class="white--text"
                      @click="adicionarHorario(relacoes.indexOf(dialogVisibleRelacaoId))"
                      >+ adicionar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>

              <v-col v-if="!editing">
                <v-timeline
                  v-show="relacoes.indexOf(dialogVisibleRelacaoId) == item.relacao_id"
                  align-top
                  dense
                  v-for="(item, i) in relacoesDiasHorarios"
                  :key="i"
                >
                  <v-timeline-item
                    ><v-chip class="mt-1" color="green" text-color="white">
                      {{ item.dia.descricao }}
                      | {{ item.horario.descricao }}
                    </v-chip>
                    <v-btn
                      class="ml-2"
                      @click="deleteRelacaoDiaHorario(i, relacoes.indexOf(dialogVisibleRelacaoId))"
                      color="error"
                      fab
                      small
                      dark
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-timeline-item>
                </v-timeline>
              </v-col>
              <v-col v-if="editing">
                <v-timeline align-top dense v-for="(item, i) in relacoesDiasHorariosEdit" :key="i">
                  <v-timeline-item>
                    <v-chip class="mt-1" color="green" text-color="white">
                      {{ item.horario.descricao }} | {{ item.dia.descricao }}
                    </v-chip>

                    <v-btn
                      class="ml-2"
                      @click="deleteRelacaoDiaHorario(i, relacoes.indexOf(dialogVisibleRelacaoId))"
                      color="error"
                      fab
                      small
                      dark
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-timeline-item>
                </v-timeline>
              </v-col>
            </v-card>
          </template>
        </v-dialog>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  mounted() {
    this.loadData();
  },

  data() {
    return {
      horariosList: [],
      submittingForm: false,
      professoresList: [],
      turmasList: [],
      form: {},
      relacoes: [{}],
      disciplinasList: [],
      carregandoProfessores: false,
      carregandoTurmas: false,
      carregandoDisciplinas: false,
      camposObrigatoriosNaoPreenchidos: false,
      relacoesDiasHorarios: [],
      relacoesDiasHorariosEdit: [],
      horario_selecionado_id: null,
      dia_da_semana: null,
      dialogVisible: false,
      diasDaSemana: [
        {
          id: 0,
          descricao: "Segunda",
        },
        {
          id: 1,
          descricao: "Terça",
        },
        {
          id: 2,
          descricao: "Quarta",
        },
        {
          id: 3,
          descricao: "Quinta",
        },
        {
          id: 4,
          descricao: "Sexta",
        },
        {
          id: 5,
          descricao: "Sábado",
        },
      ],
    };
  },
  methods: {
    alterRelacaoIdDialog(relacao) {
      this.dia_da_semana = null;
      this.horario_selecionado_id = null;
      this.dialogVisible = !this.dialogVisible;
      this.dialogVisibleRelacaoId = relacao;
      this.getHorariosDaTurma(relacao.turma_id);
    },
    async adicionarHorario(relacao) {
      if (!this.dia_da_semana || !this.horario_selecionado_id) {
        this.camposObrigatoriosNaoPreenchidos = true;
        return;
      }

      const data = {
        relacao_id: relacao,
        horario: this.horario_selecionado_id,
        dia: this.dia_da_semana,
      };

      if (!this.editing) {
        this.relacoesDiasHorarios.push(data);
        if (!this.relacoes[relacao].horarios) {
          this.relacoes[relacao].horarios = [data];
        } else {
          this.relacoes[relacao].horarios.push(data);
        }
      }
      if (this.editing) {
        this.relacoesDiasHorariosEdit.push(data);
      }

      this.camposObrigatoriosNaoPreenchidos = false;
    },

    deleteRelacaoDiaHorario(i) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esse vinculo?",
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: false,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando o vinculo");
              try {
                if (!this.editing) {
                  this.relacoesDiasHorarios.splice(i, 1);
                }

                if (this.editing) {
                  this.relacoesDiasHorariosEdit.splice(i, 1);
                }
                this.$toast.success("Vinculo deletado com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },

    async adicionarRelacao() {
      this.dia_da_semana = null;
      this.horario_selecionado_id = null;
      const novaRelacao = {};
      this.relacoes.push(novaRelacao);
    },

    async submitForm() {
      this.submittingForm = true;

      if (!this.dia_da_semana || !this.horario_selecionado_id) {
        this.$toast.danger("Preencha os campos obrigatórios: Dias da semana e Horários.");
        this.submittingForm = false; // Define submittingForm como false para permitir que o formulário seja enviado novamente após preencher os campos obrigatórios.
        return;
      }

      try {
        // Editando gestão
        if (this.editing) {
          const formVinculo = {
            ...this.relacoes[0],
            instrutor_id: this.form.instrutor_id,
            relacoesDiasHorarios: this.relacoesDiasHorariosEdit,
          };

          await this.$services.gestoesDeAulasService.atualizar(formVinculo);
          this.$toast.success("Vínculo atualizado com sucesso!");
        }

        // Criando uma nova gestão
        if (!this.editing) {
          const formRelacoes = this.relacoes.map((relacao, index) => ({
            ...relacao,
            instrutor_id: this.form.instrutor_id,
            relacoesDiasHorarios: this.relacoesDiasHorarios.filter((r) => r.relacao_id === index),
          }));

          const responseData = await this.$services.gestoesDeAulasService.criar(formRelacoes);

          this.relacoes = await responseData.filter((item) => item.errors);

          if (this.relacoes[0]?.errors?.length > 0) {
            this.$toast.danger(
              "Esse(a) professor(a) já está associado(a) à turma e ao componente especificados"
            );
          } else {
            this.$toast.success("Vínculo(s) criado(s) com sucesso!");
          }
        }

        if (this.relacoes.length === 0 || this.editing) {
          this.$router.push({ name: "gestoesDeAulas" });
        }
      } catch (error) {
        this.$toast.danger("error");
      }
      this.submittingForm = false;
    },

    async loadData() {
      try {
        this.$loaderService.open("Carregando dados");
        this.professoresList = await this.$services.professoresService.syncAll();
        this.turmasList = await this.$services.turmasService.syncAll();
        this.disciplinasList = await this.$services.disciplinasService.syncAll();
        this.horariosList = await this.$services.horariosService.syncAll();

        if (this.editing) {
          const vinculo = await this.$services.gestoesDeAulasService.gestaoGeralId(
            parseInt(this.$route.params.gestaoDeAulasId, 10)
          );

          this.relacoesDiasHorariosEdit = vinculo.relacoesDiasHorarios ?? [];

          if (vinculo) {
            this.form.instrutor_id = vinculo.instrutor_id;
            this.relacoes = [vinculo];
          }
          this.horariosList = this.horariosList
            .filter((horario) => horario.turno_id === this.relacoes[0].turma.turno_id)
            .filter((horario) => horario.configuracao_id === this.relacoes[0].turma.configuracao_id)
            .filter((horario) =>
              this.relacoes[0].turma.isInfantil
                ? horario.descricao.toLowerCase().includes("momento")
                : !horario.descricao.toLowerCase().includes("momento")
            );
        }
        this.$loaderService.close();
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async getHorariosDaTurma(turma_id) {
      this.$loaderService.open("Carregando horários");
      try {
        if (turma_id) {
          this.horariosList = await this.$services.horariosService.getHorariosDoTurnoDaTurma(
            turma_id
          );
        }
      } catch (error) {
        this.$toast.error("Erro ao carregar horários", error);
      } finally {
        this.$loaderService.close();
      }
    },
  },
};
</script>

<style></style>
