import sistemaBNCCService from "./SistemaBNCCService";
import alunosService from "./AlunosService";
import anosService from "./AnosService";
import authService from "./AuthService";
import caixasService from "./CaixasService";
import franquiasService from "./FranquiasService";
import saecService from "./SaecService";
import matriculasService from "./MatriculasService";
import formasDePagamentoService from "./FormasDePagamentoService";
import parcelasService from "./ParcelasService";
import turmasService from "./TurmasService";
import turnosService from "./TurnosService";
import seriesService from "./SeriesService";
import cursosService from "./CursosService";
import cepService from "./CepService";
import disciplinasService from "./DisciplinasService";
import professoresService from "./ProfessoresService";
import usuariosService from "./UsuariosService";
import inepService from "./InepService";
import gestoesDeAulasService from "./GestoesDeAulasService";
import aulasService from "./AulasService";
import historicoService from "./HistoricoService";
import notasService from "./NotasService";
import relatoriosService from "./RelatoriosService";
import tiposDeEnsinosService from "./TiposDeEnsinosService";
import planosBimestraisService from "./PlanosBimestraisService";
import objetivosAprendizagemService from "./ObjetivosAprendizagemService";
import rolesService from "./RolesService";
import avisosService from "./AvisosService";
import imageService from "./ImageService";
import agendasService from "./AgendaService";
import notifiqService from "./NotifiqService";
import responsavelAlunoService from "./ResponsavelAlunoService";
import horariosService from "./HorariosService";
import webInfo from "./WebInfo";
import informativosService from "./InformativosService";

// circuito de notas
import circuitoNotasService from "./CircuitoNotasService";
import etapasService from "./EtapasService";
import subEtapasService from "./SubEtapasService";
import autorizacoesService from "./AutorizacoesService";
import regrasService from "./RegrasService";
import modelosPlanoEnsinoService from "./ModelosPlanoEnsinoService";

import planosEnsinoService from "./PlanosEnsinoService";
import diarioFormacaoService from "./DiarioFormacaoService";

const $services = {
  alunosService,
  anosService,
  authService,
  caixasService,
  franquiasService,
  saecService,
  matriculasService,
  formasDePagamentoService,
  parcelasService,
  turmasService,
  turnosService,
  seriesService,
  cursosService,
  cepService,
  disciplinasService,
  professoresService,
  usuariosService,
  inepService,
  gestoesDeAulasService,
  objetivosAprendizagemService,
  aulasService,
  notasService,
  relatoriosService,
  historicoService,
  sistemaBNCCService,
  tiposDeEnsinosService,
  planosBimestraisService,
  rolesService,
  avisosService,
  imageService,
  agendasService,
  notifiqService,
  responsavelAlunoService,
  horariosService,
  circuitoNotasService,
  etapasService,
  subEtapasService,
  autorizacoesService,
  regrasService,
  webInfo,
  modelosPlanoEnsinoService,
  planosEnsinoService,
  diarioFormacaoService,
  informativosService,
};

export default $services;
