<template>
  <main-template hide-franquia>
    <v-row class="mx-3">
      <v-col cols="12" class="mx-1">
        <v-img src="/img/portal_cuidador.svg" style="max-width: 100%; height: auto" />
      </v-col>

      <v-col cols="12" class="mx-1">
        <small>Bem vindo(a)!</small>
        <v-row class="mt-2">
          <v-skeleton-loader class="mb-4" v-if="!aluno_imagem" type="avatar"></v-skeleton-loader>
          <v-avatar class="mb-4" v-else size="65px">
            <img alt="Avatar" :src="aluno_imagem" />
          </v-avatar>
          <h1 class="ml-3 mt-4">{{ this.nomeAluno }}</h1>
        </v-row>
      </v-col>

      <v-col>
        <v-alert dismissible v-if="message" outlined type="error" prominent border="left">
          {{ message }}
        </v-alert>
        <template v-if="avisos.length > 0">
          <div>
            <v-alert color="error" icon="warning" border="left" elevation="2" colored-border>
              <v-row align="center">
                <v-col class="grow"><strong>Avisos</strong> </v-col>
                <v-btn small :to="{ name: 'avisos.portal' }" color="secondary"> Ver todos </v-btn>
              </v-row>
              <div v-for="(aviso, index) in avisos" :key="index" class="mt-1">
                <v-col class="grow">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-row class="px-3" justify="space-between">
                        <span class="d-inline-block title-1 font-weight-bold text-truncate box">
                          {{ aviso.titulo ? aviso.titulo : "" }}
                        </span>
                        <v-btn icon @click="() => verAviso(aviso.id, aviso)" color="secondary">
                          <span class="material-icons">
                            <span class="material-icons"> visibility </span>
                          </span>
                        </v-btn>
                      </v-row>
                      <!-- Block level -->
                      <div>
                        <span class="d-inline-block subtitle-1 font-weight-light text-truncate box">
                          {{ aviso.contexto ? aviso.contexto : "" }}
                        </span>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </v-col>
              </div>
            </v-alert>
          </div>
        </template>
        <template>
          <div>
            <v-alert color="secondary" border="left" elevation="2" colored-border icon="mdi-book">
              <v-row align="center">
                <v-col class="grow"> Suas <strong>agendas</strong> da semana! </v-col>
                <v-btn small :to="{ name: 'agenda.aluno' }" color="secondary"> Ver todos </v-btn>
              </v-row>
              <div class="mt-1" v-for="(item, index) in agendas_semana" :key="index">
                <v-row align="center">
                  <v-col class="grow"> {{ item.dia ? item.dia : "" }} </v-col>
                  <v-col class="shrink">
                    <v-btn icon @click="() => expandirAgenda(item, index)" color="secondary">
                      <span class="material-icons">
                        {{ item.expand ? "expand_less" : "expand_more" }}
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider class="mt-1"></v-divider>
                <v-expand-transition>
                  <v-row class="mt-1" v-show="item.expand || false">
                    <v-list-item v-for="(item_agenda, i) in item.agenda" :key="i" two-line>
                      <v-list-item-content>
                        <v-list-item-title class="overline">{{
                          item_agenda.titulo ? item_agenda.titulo : ""
                        }}</v-list-item-title>
                        <v-list-item-subtitle class="caption"
                          ><v-chip label color="warning" small>
                            {{ item_agenda.professor ? item_agenda.professor.nome : "" }}</v-chip
                          >
                        </v-list-item-subtitle>
                        <div class="caption font-weight-hint">
                          {{ item_agenda.conteudo ? item_agenda.conteudo : "" }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                </v-expand-transition>
              </div>
            </v-alert>
          </div>
        </template>
        <h2>Meus Diários</h2>
        <v-col class="pt-0">
          <v-data-table
            :headers="table.headers"
            :items="gestoesDeAulasList"
            :loading="carregandoGestoesDeAulas"
            :items-per-page="10"
            no-data-text="Não foram encontrádos vínculos para você, por favor entre em contato com a secretaria/coordenação para que lhe adicionem acesso às turmas e disciplinas"
            class="elevation-1"
          >
            <template v-slot:body="{ items }">
              <tbody name="scroll-x-transition" is="transition-group" duration="150">
                <tr color="primary" v-for="gestaoDeAulas of items" :key="gestaoDeAulas.id">
                  <td>{{ gestaoDeAulas.id }}</td>
                  <td width="18%">
                    {{ gestaoDeAulas.turma_formacao?.descricao }}
                  </td>
                  <td width="25%">
                    {{ gestaoDeAulas.user?.name }}
                  </td>
                  <td width="7%">
                    <div class="text-center">
                      <v-menu :close-on-content-click="false" :nudge-width="100" offset-x>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn x-small color="blue" class="white--text" v-bind="attrs" v-on="on">
                            AÇÕES
                          </v-btn>
                        </template>

                        <v-card class="mx-auto" max-width="300">
                          <v-list>
                            <v-list-item-group color="primary"> </v-list-item-group>
                            <!-- item -->
                            <v-list-item-group color="primary">
                              <v-list-item @click="() => verEncontros(gestaoDeAulas)">
                                <v-list-item-icon>
                                  <v-icon small>fa fa-eye</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Encontros</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                            <v-list-item-group color="primary">
                              <v-list-item @click="() => verRelatorios(gestaoDeAulas)">
                                <v-list-item-icon>
                                  <v-icon small>fa fa-eye</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Relatorios de Frequência</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>

          <e-select-months-modal
            @dialogChange="dialog = $event"
            :dialog="dialog"
            confirmText="Gerar plano"
            cancelText="Fechar"
          >
          </e-select-months-modal>

          <div v-if="dialogConfirmarAulas">
            <e-dialog-search-aulas-interval-date
              :gestao_selected_to_interval="gestao_selected_to_interval"
              :dialogConfirmarAulas="dialogConfirmarAulas"
              @dialogAulasChange="dialogConfirmarAulas = $event"
            >
            </e-dialog-search-aulas-interval-date>
          </div>
        </v-col>
      </v-col>
    </v-row>
    <e-select-months-modal
      @dialogChange="dialog = $event"
      :dialog="dialog"
      confirmText="Gerar frequência"
      cancelText="Fechar"
      @changeAction="frenquenciaIndividual"
    ></e-select-months-modal>
    <div class="text-center">
      <v-dialog v-model="dialog_aviso" width="374">
        <v-card v-if="!aviso" class="mx-auto" max-width="374">
          <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
            <v-skeleton-loader class="mx-auto" max-width="374" type="card"></v-skeleton-loader>
          </v-sheet>
        </v-card>

        <v-card v-else class="mx-auto" max-width="374">
          <v-img :src="url_image_aviso" height="200px"></v-img>

          <v-card-title> {{ aviso ? aviso.titulo : "- - -" }} </v-card-title>

          <v-card-subtitle> {{ aviso ? aviso.contexto : "- - -" }} </v-card-subtitle>

          <v-card-actions>
            <v-btn @click="dialog_aviso = false" color="orange lighten-2" text> Fechar </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  data() {
    return {
      nomeAluno: "",
      historicoAluno: [],
      gestoesDeAulasList: [],
      carregandoGestoesDeAulas: false,
      dialogConfirmarAulas: false,
      message: null,
      pageNumber: 0,
      dialog: false,
      dialog_aviso: false,
      matricula_selected: null,
      expand_agenda: false,
      input: "",
      agendas_semana: [],
      matricula_atual: null,
      tab: null,
      agenda: [],
      aluno_imagem: null,
      avisos: [],
      aviso: null,
      url_image_aviso: null,
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Turma", value: "nomeTurma" },
          { text: "Formador", value: "nomeProfessor" },
          {
            text: "",
            value: "actions",
            sortable: false,
            width: "200px",
          },
        ],
      },
    };
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  async mounted() {
    this.$loaderService.open("Carregando gestoesDeAulas");
    try {
      await this.listarGestoesDeAulas(this.pageNumber);
    } catch (error) {
      this.$handleError(error);
    }
    this.$loaderService.close();
  },
  watch: {
    carregandoGestoesDeAulas(value) {
      if (value && this.gestoesDeAulasList.length === 0) {
        this.$loaderService.open("Carregando gestoesDeAulas");
      } else {
        this.$loaderService.close();
      }
    },
    async search(val) {
      this.carregandoAvisos = !this.carregandoAvisos;
      if (val.length > 2) {
        await this.listarGestoesDeAulas(0, val);
      }
      if (!val) {
        await this.listarGestoesDeAulas(0);
      }
      this.carregandoAvisos = !this.carregandoAvisos;
    },
  },
  methods: {
    async listarGestoesDeAulas(pageNumber, query = null) {
      this.carregandoGestoesDeAulas = true;
      try {
        const response = await this.$services.diarioFormacaoService.vinculos();
        this.gestoesDeAulasList = response;
      } catch {
        this.carregandoGestoesDeAulas = false;
      }
      this.carregandoGestoesDeAulas = false;
    },
    expandirAgenda(agenda, index) {
      agenda.expand = !agenda.expand;
      this.$set(this.agendas_semana, index, agenda);
    },
    openModalSelectMonths(matricula_id) {
      this.matricula_selected = matricula_id;
      this.dialog = !this.dialog;
    },
    async pegarNomeAluno() {
      this.nomeAluno = this.user.name.toLowerCase();
      const nome = this.nomeAluno.charAt(0).toUpperCase();
      this.nomeAluno = nome + this.nomeAluno.slice(1);
    },
    async matriculas() {
      const { aluno_id } = this.user;
      const response = await this.$services.matriculasService.historico(aluno_id);
      this.historicoAluno = response.data.alunos;
      this.agendasDaSemana(response.data.matricula_ano_atual.turma_id);
      this.avisosLimit(response.data.matricula_ano_atual.turma_id);
    },
    async boletim(matricula_id) {
      this.$loaderService.open("Carregando dados...");
      try {
        await this.$services.responsavelAlunoService.boletim(parseInt(matricula_id, 10));
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async agendasDaSemana(turma_id) {
      try {
        const response = await this.$services.agendasService.agendasDaSemana(turma_id);
        this.agendas_semana = response;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async avisosLimit(turma_id) {
      try {
        const response = await this.$services.avisosService.avisosLimit(turma_id);
        this.avisos = response.avisos;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async frenquenciaIndividual(mes) {
      this.$loaderService.open("Carregando dados...");
      try {
        await this.$services.relatoriosService.frenquenciaIndividual(
          parseInt(this.matricula_selected, 10),
          parseInt(mes.id, 10)
        );
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async relatorioBncc(matricula_id) {
      try {
        this.$loaderService.open("Carregando dados");
        await this.$services.responsavelAlunoService.relatorioBncc(parseInt(matricula_id, 10));
        this.$loaderService.close();
      } catch (error) {
        this.$loaderService.close();
        this.message = "A turma não esta vinculado a um curso, entre em contato com a direção!";
      }
    },
    async desempenhoDoAluno(matricula_id) {
      this.$loaderService.open("Carregando dados...");
      try {
        this.$router.push({
          name: "matriculas.desempenhoNota",
          params: { matricula_id },
        });
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async verImage() {
      this.aluno_imagem = await this.$services.imageService.downloadImage(
        `notifiq/matriculas/download-image-aluno/${this.user.aluno_id}`
      );
    },
    async verAviso(aviso_id, aviso) {
      this.dialog_aviso = !this.dialog_aviso;
      this.$forceUpdate();
      this.url_image_aviso = await this.$services.imageService.downloadImage(
        `notifiq/avisos/download-image/${aviso_id}`
      );
      this.aviso = aviso;
    },
    async verEncontros(gestaoDeAula) {
      this.$router.push({
        name: "diario-formacao-encontros",
        params: {
          vinculo: gestaoDeAula.id,
        },
      });
    },
    async verRelatorios(gestaoDeAula) {
      this.$router.push({
        name: "diario-formacao.encontros.relatorio.vinculo",
        params: {
          vinculo: gestaoDeAula.id,
        },
      });
    },
  },
};
</script>
<style scoped>
@media only screen and (min-width: 768px) {
  /* For desktop: */
  .box {
    width: 650px;
  }
}

@media only screen and (max-width: 480px) {
  /* For desktop: */
  .box {
    width: 200px;
  }
}
</style>
