import Axios from "@/plugins/Axios";
import store from "@/store";

export class InformativosService {
  async syncAll() {
    const response = await Axios().get("informativos");
    const infos = response.data;
    return infos;
  }

  async create(dados) {
    const response = await Axios().post("informativos", dados);
    const info = response.data;
    return info;
  }

  async delete() {
    const response = await Axios().get(`informativos/deletar-todos`);
    const infor = response.data;
    return infor;
  }
}
export default new InformativosService();
